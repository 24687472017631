import request from '@/utils/request';
import Vue from 'vue';

//留言反馈
export function add_message(data) {
  return request({
    url: '/message/add_message?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}
//留言列表
export function message_lists(data) {
  return request({
    url: '/message/lists?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}
