<template>
  <div class="remobileView">
    <van-nav-bar
      title="提交工单"
      right-text="查看工单"
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    />
    <div class="content_box text-center">
      <h2 class="van-title text-left">标题</h2>
      <van-cell-group>
        <van-field v-model="title" placeholder="请输入留言标题" />
      </van-cell-group>

      <h2 class="van-title text-left">内容</h2>
      <van-cell-group>
        <van-field
          border
          v-model="content"
          rows="4"
          autosize
          type="textarea"
          maxlength="200"
          placeholder="请输入留言内容"
          show-word-limit
        />
      </van-cell-group>
      <h2 class="van-title text-left">上传图片</h2>
      <div style="text-align:left">
        <van-uploader
          v-model="fileList"
          :max-count="1"
          preview-size="100"
          :before-read="beforeRead"
          :after-read="afterRead"
        />
      </div>
      <van-button type="info" class="big_btn" @click="submitHandler">提交</van-button>
      <van-button type="primary" class="big_other_btn" @click="toCustomer" style="margin:0">联系客服</van-button>
    </div>
  </div>
</template>
<script>
import { saveToLocal, loadFromLocal } from "@/utils/local-storage";
import { add_message, collectionsDetail } from "@/api/message";

export default {
  name: "login",
  data() {
    return {
      fileList: [],
      title: "",
      content: "",
      image: ""
    };
  },
  methods: {
    // 返回布尔值
    beforeRead(file) {
      if (file.type !== "image/jpeg" && file.type !== "image/png") {
        this.$toast("图片格式不正确，请确认");
        return false;
      }
      return true;
    },
    afterRead(file) {
      this.image = file;
    },
    toCustomer() {
      this.$router.push("/main/user/add_message/customer");
    },
    onClickRight() {
      this.$router.push("/main/user/add_message/message_lists");
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    submitHandler(e) {
      const { title, content, image } = this;
      let field = { title, content };
      //校验表单
      var objSome = Object.getOwnPropertyNames(field).some(key => {
        let validInfo = this.$validate[
          "valid" + key.charAt(0).toUpperCase() + key.slice(1)
        ](field[key], field);
        if (!validInfo.status) {
          this.$toast({
            message: this.$t(validInfo.msg),
            duration: 1000
          });
          return true;
        } else {
          return false;
        }
      });

      if (!objSome) {
        add_message({ title, content, image })
          .then(response => {
            this.$toast({
              message: response.msg,
              duration: 500,
              forbidClick: true
            });
            setTimeout(() => {
              this.$router.replace("/main/user/add_message/message_lists");
            }, 500);
          })
          .catch(error => {});
      }
    }
  }
};
</script>

<style lang="stylus" rel="stylesheet/stylus">
.remobileView
  .content_box
    background-color: $whiteTextColor
    padding: 0.6rem 0.4rem
    margin: 0.3rem
    box-shadow: 0px 0px 0.2rem #dfdff9
    .van-cell-group
      border: 1px solid #f1ecec
    .van-title
      padding-top: 0.46rem
    .mobile
      .van-cell
        padding: 0.26667rem 0.2rem 0.26667rem 0.42667rem
      .verify
        margin-top: 0.28rem
    h2
      color: $grayTextColor
    .van-icon::before
      color: $grayTextColor
    .van-icon
      transform: rotate(90deg)
</style>
